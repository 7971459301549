@import 'partials/constructor.scss';
@import 'partials/footer.scss';
@import 'partials/header.scss';
@import 'partials/news.scss';
@import 'partials/tarifi.scss';
@import 'partials/index.scss';
@import 'partials/grid_pyrex.scss';

html,
body{
	font-family: 'Proxima nova', sans-serif;
	height: 100%;
	width: 100%;
  margin: 0;
}
.col-md-4{
  padding-left: 20px;
  padding-right: 20px;
}
// @import url('https://fonts.googleapis.com/css?family=Proxima+Nova');
@media (max-width: 1191px){

}
@media (max-width: 991px){

}
@media(max-width: 768px){

}
@media(max-width: 600px){

}
@media(max-width: 480px){

}
@media(max-width: 320px){

}
@media only screen and (max-device-width: 1366px) {
    .parallax {
      background-attachment: scroll;
    }
}
@media (min-width:1200px){.container{max-width:991px}}
@media (min-width:1300px){.container{max-width:1100px}}
@media (min-width:1400px){.container{max-width:1200px}}
@media (min-width:1580px){.container{max-width:1520px}}



@media(max-width: 380px){
	.sub-nav .opacity{
		display: none;
	}
}