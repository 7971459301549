.banner{
    min-height: 100%;
    max-width: 100%;
    .banner-image{
        // background-image: url("../img/banner.jpg");
        // <img src="img/banner.jpg" alt="Blue sky" class="banner-image">
        width: 100%;
        height: 450px;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
    }
    .banner-text{
        position: absolute;
        top: 0;
        // bottom: 90%;
        width: 100%;
        color: #fff;
        padding: 0;
        margin-top: 0;
        text-decoration: none;  
        .sub-nav {
            list-style-type: none;
            margin: 0;
            margin-bottom: 15px;
            padding: 0;
            overflow: hidden;
            background-color: #27436656;
            // opacity: 0.4;
            // .opacity{
            //     opacity: 1;
            // }
            li {
                float: left;
                font-weight: bold;
                a{
                    display: block;
                    color: white;
                    text-align: center;
                    padding: 10px 13px;
                    text-decoration: none;
                    font-size: 11px;
                    opacity: 1;
                  }
                
            }
            .active-lan {
                margin: 7px;
                background-color: #b23c40;
                border-radius: 5px;
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 4px;
                padding-right: 4px;
            }
        }
        .row{
            margin-bottom: 15px;
            .icon{  
                margin-left: 12px;
                margin-top: 8px;
            }
            a{
                display: block;
                color: white;
                padding: 10px 8px;
                text-decoration: none;
                font-weight: bold;
                font-size: 14px;
                padding-left: 20%;
                padding-bottom: 1px; 
                opacity: 1;
                .nav-fig{
                    margin-top: 30px;
                }
                p{
                    font-size: 10px;
                    color: #a8c1d9;
                }
            }
            .active{
                background-color: #a8c1d95e;
                // opacity: 0.4;
                &:after{
                    content: '';
                    top: 75%;
                    left: 50%;
                    position: absolute;
                    border-left: 5.5px solid transparent;
                    border-right: 5.5px solid transparent;
                    border-top: 6px solid #a8c1d95e;
                    margin-left: -5.5px;
                }
            }
            .cabinet{
                margin-top: 5px;
                background-color: #2d527e;
                text-align: center;
                border-radius: 5px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        hr{
            background-color: #6180a6;
        }
        
        .sev-class {
            list-style-type: none;
            margin: 0;
            margin-top: 30px;
            padding: 0;
            overflow: hidden;
          }
          
          li {
            float: left;
          }
          
          li a {
            display: block;
            color: white;
            text-align: center;
            padding: 14px 16px;
            text-decoration: none;
            font-size: 13px;
          }
          li a .SkyService{
              // border: 1px dashed;
              border-color: #fbfbfc;
              color: #fff;
              padding: 12px;
              font-weight: bold;
          }

        .humburger{
            font-size: 30px;
        }
        .nav-small-size{
            list-style-type: none;
            .icon-sm-size{
                margin-top: 3px;
                text-decoration: none;
                list-style-type: none;
            }
            .icon-sm-size{
                width: 32%;
            }
            .cabinet-small-nav{
                display: inline-block;
                margin-top: 10px;
                margin-left: 25px;
                background-color: #2d527e;
                border-radius: 5px;
                padding: 6px 12px;
                text-align: right;
            }
            .hum-small-nav{
                display: inline-block;
                margin-top: 3px;
            }
            background-color: #27436656;
            
        }
        .sidebar{
            width: 250px;
            height: 100%; /* 100% Full-height */
            position: fixed; /* Stay in place */
            z-index: 1; /* Stay on top */
            display:none;
            top: 0; /* Stay at the top */
            left: 0;
            color: #fff;
            background-color: #14416e;
            overflow-x: hidden; /* Disable horizontal scroll */
            transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
            .first{
                .closebtn {
                    font-size: 34px;
                    padding-left: 20px;
                    padding-right: 20px;
                    line-height: 1.3;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-left: 44px;
                    &:hover{
                        background-color: #32547cd5;
                        color: #fff;
                        text-decoration: none;
                    }
                }
                .language{
                    list-style-type: none;
                    padding: 0;
                    margin-top: 6px;
                    overflow: hidden;
                    li {
                        float: left;
                        font-weight: bold;
                        a{
                            padding: 8px 8px 8px 32px;
                            text-decoration: none;
                            font-size: 25px;
                            color: #818181;
                            display: block;
                            transition: 0.3s;
                            // something
                            display: block;
                            color: white;
                            text-align: center;
                            padding: 10px 13px;
                            text-decoration: none;
                            font-size: 11px;
                            opacity: 1;
                        }
                        &:first-child{
                            margin-left: 20px;
                        }
                        .active-lan {
                            margin: 7px;
                            background-color: #b23c40;
                            border-radius: 5px;
                            padding-top: 2px;
                            padding-bottom: 2px;
                            padding-left: 4px;
                            padding-right: 4px;
                            // something
                            transition: 0.3s;
                        }
                        
                    }

                }
            }
            .important-links{
                margin-left: 20px;
                margin-top: 10px;
                margin-bottom: 10px;
                background-color: rgb(20, 65, 110);
                .card{
                    background-color: rgb(20, 65, 110);
                    .card-header{
                        border-radius: none;
                        padding: 0;
                        background-color: rgb(20, 65, 110);
                        border-bottom: none;
                        h2{
                            button{
                                color: #fff;
                                text-decoration: none;
                                #right-point{
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                    .coll-show{
                        .card-body{
                            a{
                                color: #fff;
                                text-decoration: none;
                                margin-right: 20px;
                                &:hover{
                                    color: #fff;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
            .additional-link{
                .add-links{
                    padding-left: 0;
                    li{
                        list-style-type: none;
                        a{
                            text-decoration: none;
                            display: block;
                            padding: 10px;
                            margin: 0;
                            color: #fff;
                            margin-left: 20px;
                            padding-left: 0; 
                            font-size: 13px;
                            

                        }
                        &:first-child{
                            margin-right: 90px;    
                        }
                    }
                }
            }    
        }    
    }
}

@media (max-width: 1191px){

}
@media (max-width: 991px){

}
@media(max-width: 766px){
    .sub-nav{
        display: none;
    }
    .main-nav{
        display: none;
    }
}
@media(max-width: 600px){

}
@media(max-width: 480px){

}
@media(max-width: 320px){

}
@media(min-width: 768px){
    .nav-small-size{
        display: none;
    }
    .humburger{
        display: none;}
}
@media (min-width:1400px){
    .container{
        max-width:1360px
    }
    .humburger{
        display: none;
    }
    .banner{
        .row{
            a{
                display: block;
                text-align: center;
                color: white;
                padding: 17px 2px;
                text-decoration: none;
                font-weight: bold;
                font-size: 14px;
                padding-left: 3%;
                padding-bottom: 1px; 
                opacity: 1;
                .nav-fig{
                    margin-top: 30px;
                }
                p{
                    font-size: 10px;
                    color: #a8c1d9;
                }
            }
            .active{
                background-color: #a8c1d95e;
                // opacity: 0.4;
                &:after{
                    content: '';
                    top: 100%;
                    left: 50%;
                    position: absolute;
                    border-left: 5.5px solid transparent;
                    border-right: 5.5px solid transparent;
                    border-top: 6px solid #a8c1d95e;
                    margin-left: -5.5px;
                }
            }
        }
    }

}
@media (min-width:1200px){
    .container{max-width:1160px}
        .humburger{
        display: none;}
    .banner{
        .row{
            a{
                display: block;
                text-align: center;
                color: white;
                padding: 17px 2px;
                text-decoration: none;
                font-weight: bold;
                font-size: 14px;
                padding-left: 3%;
                padding-bottom: 1px; 
                opacity: 1;
                .nav-fig{
                    margin-top: 30px;
                }
                p{
                    font-size: 10px;
                    color: #a8c1d9;
                }
            }
            .active{
                background-color: #a8c1d95e;
                // opacity: 0.4;
                &:after{
                    content: '';
                    top: 100%;
                    left: 50%;
                    position: absolute;
                    border-left: 5.5px solid transparent;
                    border-right: 5.5px solid transparent;
                    border-top: 6px solid #a8c1d95e;
                    margin-left: -5.5px;
                }
            }
        }
    }
}
@media (min-width:1300px){
    .container{max-width:1270px}
    .humburger{
        display: none;}
    .banner .banner-text .row a {
        display: block;
        color: #fff;
        padding: 10px 8px 1px 20%;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        opacity: 1;
    }
}
@media (min-width:1400px){
    .container{
        max-width:1200px;
    }
    .humburger{
        display: none;}
    .banner .banner-text .row .active:after {
        content: '';
        top: 100%;
        left: 50%;
        position: absolute;
        border-left: 5.5px solid transparent;
        border-right: 5.5px solid transparent;
        border-top: 6px solid #a8c1d95e;
        margin-left: -5.5px;
    }
}
@media (min-width:1580px){
    .container{max-width:1520px}
    .humburger{
        display: none;}
    .banner .banner-text .row a {
        display: block;
        color: #fff;
        padding: 18px 8px 1px 2%;
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        opacity: 1;
    }
    .pyrex_logo {
        padding:10px 0 !important;
        img{
            height: 50px;
            padding:0;
        }
    }
}
.sub-nav > .opacity > li:first-of-type a{
    padding-left:0;
}
.main-logo{
    max-width: 210px;
    height: 34px;
}
// .sub-nav > .opacity > li:last-of-type a{
//     padding-right:0;
// }
.service span.active{
    border: 1px dashed #fbfbfc;
    color: #0a274a !important;
    padding: 12px;
}
