.content{
    margin-top: 15px;
    .main{
        .row{ 
            margin-top: 20px;  
            .first-title{
                background-color: #3d74ab;
                .text{
                    margin: 5px;
                    .title{
                        font-size: 19px;
                        font-style: bold;
                        color: #fff;
                        span{
                            font-size: 9px;
                            font-style: regular;
                        }
                    }
                    p{
                        color: #ffee2d;
                    }
                    .row{
                        .cost{
                            p{
                                color: #a1d4f6;
                                font-size: 19px;
                                line-height: 0.8em;
                                span{
                                    margin-top: 0;
                                    color: #fff;
                                    font-size: 9px;
                                } 
                            }
                        }
                        .with-button{
                            text-align: center;
                            a{
                                background-color: #5c8fbd;
                                color: #fff;
                                padding: 12px;
                                font-size: 12px;
                                border-radius: 5px;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .content-main{
                background-color: #f1f8ff;
                padding-top: 7px;
                padding-bottom: 5px;
                .text{
                    margin-top: 10px;
                    margin: 5px;
                    font-size: 12px;   
                    color: #212f3d;
                    line-height: 0.1em;
                    .dif-col{
                        color: #616f7d;
                        font-size: 12px;
                        line-height: 1em;
                    }
                }
            }
        }
    }
}
@media (max-width: 1191px){

}
@media (max-width: 991px){

}
@media(max-width: 768px){

}
@media(max-width: 600px){

}
@media(max-width: 480px){

}
@media(max-width: 320px){

}