.footer{
    background-color: #30537b;
    color: #fff;
    clear: both;
    padding-top: 30px;
    h6{
        font-weight: bold;
    }
    .last{
        line-height: 30px;
        font-size: 13px;
        color: #a8c1d9;
    }
    .addition{
        line-height: 30px;
    }
    h4{
        margin-bottom: 40px;
        font-size: 25px;
    }
    .first-p{
        font-size: 15px;
    }
    h6{
        font-size: 15px;
    }
}
@media (max-width: 1191px){

}
@media (max-width: 991px){

}
@media(max-width: 768px){

}
@media(max-width: 600px){

}
@media(max-width: 480px){

}
@media(max-width: 320px){

}

.more-links a{
    font-size: 13px;
    color: #a8c1d9;
}
.more-links a:hover{
    text-decoration: none;
}
.footer_ul{
    padding:0;
    list-style: none;
    color: #fff;
    a{
        color:#fff;
    }
}
.footer{
    
}
