.col-xss-1, .col-smm-1, .col-mdd-1, .col-lgg-1, .col-xss-2, .col-smm-2, .col-mdd-2, .col-lgg-2, .col-xss-3, .col-smm-3, .col-mdd-3, .col-lgg-3, .col-xss-4, .col-smm-4, .col-mdd-4, .col-lgg-4, .col-xss-5, .col-smm-5, .col-mdd-5, .col-lgg-5, .col-xss-6, .col-smm-6, .col-mdd-6, .col-lgg-6, .col-xss-7, .col-smm-7, .col-mdd-7, .col-lgg-7, .col-xss-8, .col-smm-8, .col-mdd-8, .col-lgg-8, .col-xss-9, .col-smm-9, .col-mdd-9, .col-lgg-9, .col-xss-10, .col-smm-10, .col-mdd-10, .col-lgg-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xss-1, .col-xss-2, .col-xss-3, .col-xss-4, .col-xss-5, .col-xss-6, .col-xss-7, .col-xss-8, .col-xss-9, .col-xss-10 {
  float: left;
}
.col-xss-10 {
  width: 100%;
}
.col-xss-9 {
  width: 90%;
}
.col-xss-8 {
  width: 80%;
}
.col-xss-7 {
  width: 70%;
}
.col-xss-6 {
  width: 60%;
}
.col-xss-5 {
  width: 50%;
}
.col-xss-4 {
  width: 40%;
}
.col-xss-3 {
  width: 30%;
}
.col-xss-2 {
  width: 20%;
}
.col-xss-1 {
  width: 10%;
}
.col-xss-pull-10 {
  right: 100%;
}
.col-xss-pull-9 {
  right: 90%;
}
.col-xss-pull-8 {
  right: 80%;
}
.col-xss-pull-7 {
  right: 70%;
}
.col-xss-pull-6 {
  right: 60%;
}
.col-xss-pull-5 {
  right: 50%;
}
.col-xss-pull-4 {
  right: 40%;
}
.col-xss-pull-3 {
  right: 30%;
}
.col-xss-pull-2 {
  right: 20%;
}
.col-xss-pull-1 {
  right: 10%;
}
.col-xss-pull-0 {
  right: auto;
}
.col-xss-push-10 {
  left: 100%;
}
.col-xss-push-9 {
  left: 90%;
}
.col-xss-push-8 {
  left: 80%;
}
.col-xss-push-7 {
  left: 70%;
}
.col-xss-push-6 {
  left: 60%;
}
.col-xss-push-5 {
  left: 50%;
}
.col-xss-push-4 {
  left: 40%;
}
.col-xss-push-3 {
  left: 30%;
}
.col-xss-push-2 {
  left: 20%;
}
.col-xss-push-1 {
  left: 10%;
}
.col-xss-push-0 {
  left: auto;
}
.col-xss-offset-10 {
  margin-left: 100%;
}
.col-xss-offset-9 {
  margin-left: 90%;
}
.col-xss-offset-8 {
  margin-left: 80%;
}
.col-xss-offset-7 {
  margin-left: 70%;
}
.col-xss-offset-6 {
  margin-left: 60%;
}
.col-xss-offset-5 {
  margin-left: 50%;
}
.col-xss-offset-4 {
  margin-left: 40%;
}
.col-xss-offset-3 {
  margin-left: 30%;
}
.col-xss-offset-2 {
  margin-left: 20%;
}
.col-xss-offset-1 {
  margin-left: 10%;
}
.col-xss-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-smm-1, .col-smm-2, .col-smm-3, .col-smm-4, .col-smm-5, .col-smm-6, .col-smm-7, .col-smm-8, .col-smm-9, .col-smm-10 {
    float: left;
  }
  .col-smm-10 {
    width: 100%;
  }
  .col-smm-9 {
    width: 90%;
  }
  .col-smm-8 {
    width: 80%;
  }
  .col-smm-7 {
    width: 70%;
  }
  .col-smm-6 {
    width: 60%;
  }
  .col-smm-5 {
    width: 50%;
  }
  .col-smm-4 {
    width: 40%;
  }
  .col-smm-3 {
    width: 30%;
  }
  .col-smm-2 {
    width: 20%;
  }
  .col-smm-1 {
    width: 10%;
  }
  .col-smm-pull-10 {
    right: 100%;
  }
  .col-smm-pull-9 {
    right: 90%;
  }
  .col-smm-pull-8 {
    right: 80%;
  }
  .col-smm-pull-7 {
    right: 70%;
  }
  .col-smm-pull-6 {
    right: 60%;
  }
  .col-smm-pull-5 {
    right: 50%;
  }
  .col-smm-pull-4 {
    right: 40%;
  }
  .col-smm-pull-3 {
    right: 30%;
  }
  .col-smm-pull-2 {
    right: 20%;
  }
  .col-smm-pull-1 {
    right: 10%;
  }
  .col-smm-pull-0 {
    right: auto;
  }
  .col-smm-push-10 {
    left: 100%;
  }
  .col-smm-push-9 {
    left: 90%;
  }
  .col-smm-push-8 {
    left: 80%;
  }
  .col-smm-push-7 {
    left: 70%;
  }
  .col-smm-push-6 {
    left: 60%;
  }
  .col-smm-push-5 {
    left: 50%;
  }
  .col-smm-push-4 {
    left: 40%;
  }
  .col-smm-push-3 {
    left: 30%;
  }
  .col-smm-push-2 {
    left: 20%;
  }
  .col-smm-push-1 {
    left: 10%;
  }
  .col-smm-push-0 {
    left: auto;
  }
  .col-smm-offset-10 {
    margin-left: 100%;
  }
  .col-smm-offset-9 {
    margin-left: 90%;
  }
  .col-smm-offset-8 {
    margin-left: 80%;
  }
  .col-smm-offset-7 {
    margin-left: 70%;
  }
  .col-smm-offset-6 {
    margin-left: 60%;
  }
  .col-smm-offset-5 {
    margin-left: 50%;
  }
  .col-smm-offset-4 {
    margin-left: 40%;
  }
  .col-smm-offset-3 {
    margin-left: 30%;
  }
  .col-smm-offset-2 {
    margin-left: 20%;
  }
  .col-smm-offset-1 {
    margin-left: 10%;
  }
  .col-smm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-mdd-1, .col-mdd-2, .col-mdd-3, .col-mdd-4, .col-mdd-5, .col-mdd-6, .col-mdd-7, .col-mdd-8, .col-mdd-9, .col-mdd-10 {
    float: left;
  }
  .col-mdd-10 {
    width: 100%;
  }
  .col-mdd-9 {
    width: 90%;
  }
  .col-mdd-8 {
    width: 80%;
  }
  .col-mdd-7 {
    width: 70%;
  }
  .col-mdd-6 {
    width: 60%;
  }
  .col-mdd-5 {
    width: 50%;
  }
  .col-mdd-4 {
    width: 40%;
  }
  .col-mdd-3 {
    width: 30%;
  }
  .col-mdd-2 {
    width: 20%;
  }
  .col-mdd-1 {
    width: 10%;
  }
  .col-mdd-pull-10 {
    right: 100%;
  }
  .col-mdd-pull-9 {
    right: 90%;
  }
  .col-mdd-pull-8 {
    right: 80%;
  }
  .col-mdd-pull-7 {
    right: 70%;
  }
  .col-mdd-pull-6 {
    right: 60%;
  }
  .col-mdd-pull-5 {
    right: 50%;
  }
  .col-mdd-pull-4 {
    right: 40%;
  }
  .col-mdd-pull-3 {
    right: 30%;
  }
  .col-mdd-pull-2 {
    right: 20%;
  }
  .col-mdd-pull-1 {
    right: 10%;
  }
  .col-mdd-pull-0 {
    right: auto;
  }
  .col-mdd-push-10 {
    left: 100%;
  }
  .col-mdd-push-9 {
    left: 90%;
  }
  .col-mdd-push-8 {
    left: 80%;
  }
  .col-mdd-push-7 {
    left: 70%;
  }
  .col-mdd-push-6 {
    left: 60%;
  }
  .col-mdd-push-5 {
    left: 50%;
  }
  .col-mdd-push-4 {
    left: 40%;
  }
  .col-mdd-push-3 {
    left: 30%;
  }
  .col-mdd-push-2 {
    left: 20%;
  }
  .col-mdd-push-1 {
    left: 10%;
  }
  .col-mdd-push-0 {
    left: auto;
  }
  .col-mdd-offset-10 {
    margin-left: 100%;
  }
  .col-mdd-offset-9 {
    margin-left: 90%;
  }
  .col-mdd-offset-8 {
    margin-left: 80%;
  }
  .col-mdd-offset-7 {
    margin-left: 70%;
  }
  .col-mdd-offset-6 {
    margin-left: 60%;
  }
  .col-mdd-offset-5 {
    margin-left: 50%;
  }
  .col-mdd-offset-4 {
    margin-left: 40%;
  }
  .col-mdd-offset-3 {
    margin-left: 30%;
  }
  .col-mdd-offset-2 {
    margin-left: 20%;
  }
  .col-mdd-offset-1 {
    margin-left: 10%;
  }
  .col-mdd-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lgg-1, .col-lgg-2, .col-lgg-3, .col-lgg-4, .col-lgg-5, .col-lgg-6, .col-lgg-7, .col-lgg-8, .col-lgg-9, .col-lgg-10 {
    float: left;
  }
  .col-lgg-10 {
    width: 100%;
  }
  .col-lgg-9 {
    width: 90%;
  }
  .col-lgg-8 {
    width: 80%;
  }
  .col-lgg-7 {
    width: 70%;
  }
  .col-lgg-6 {
    width: 60%;
  }
  .col-lgg-5 {
    width: 50%;
  }
  .col-lgg-4 {
    width: 40%;
  }
  .col-lgg-3 {
    width: 30%;
  }
  .col-lgg-2 {
    width: 20%;
  }
  .col-lgg-1 {
    width: 10%;
  }
  .col-lgg-pull-10 {
    right: 100%;
  }
  .col-lgg-pull-9 {
    right: 90%;
  }
  .col-lgg-pull-8 {
    right: 80%;
  }
  .col-lgg-pull-7 {
    right: 70%;
  }
  .col-lgg-pull-6 {
    right: 60%;
  }
  .col-lgg-pull-5 {
    right: 50%;
  }
  .col-lgg-pull-4 {
    right: 40%;
  }
  .col-lgg-pull-3 {
    right: 30%;
  }
  .col-lgg-pull-2 {
    right: 20%;
  }
  .col-lgg-pull-1 {
    right: 10%;
  }
  .col-lgg-pull-0 {
    right: auto;
  }
  .col-lgg-push-10 {
    left: 100%;
  }
  .col-lgg-push-9 {
    left: 90%;
  }
  .col-lgg-push-8 {
    left: 80%;
  }
  .col-lgg-push-7 {
    left: 70%;
  }
  .col-lgg-push-6 {
    left: 60%;
  }
  .col-lgg-push-5 {
    left: 50%;
  }
  .col-lgg-push-4 {
    left: 40%;
  }
  .col-lgg-push-3 {
    left: 30%;
  }
  .col-lgg-push-2 {
    left: 20%;
  }
  .col-lgg-push-1 {
    left: 10%;
  }
  .col-lgg-push-0 {
    left: auto;
  }
  .col-lgg-offset-10 {
    margin-left: 100%;
  }
  .col-lgg-offset-9 {
    margin-left: 90%;
  }
  .col-lgg-offset-8 {
    margin-left: 80%;
  }
  .col-lgg-offset-7 {
    margin-left: 70%;
  }
  .col-lgg-offset-6 {
    margin-left: 60%;
  }
  .col-lgg-offset-5 {
    margin-left: 50%;
  }
  .col-lgg-offset-4 {
    margin-left: 40%;
  }
  .col-lgg-offset-3 {
    margin-left: 30%;
  }
  .col-lgg-offset-2 {
    margin-left: 20%;
  }
  .col-lgg-offset-1 {
    margin-left: 10%;
  }
  .col-lgg-offset-0 {
    margin-left: 0%;
  }
}
