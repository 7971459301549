.main{
    .chooseOS{
        margin-bottom: 50px;
        width: 90%;
        .chooseOS-title{
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
            color: #2d527e;
            line-height: 0.4em;
        }
        // .chooseOS-radio-btn{
        //     .radio-btn{
        //         color: #2d527e;
        //     }
        // }
        input[type="radio"] {
            /* remove standard background appearance */
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            /* create custom radiobutton appearance */
            display: inline-block;
            width: 18px;
            height: 18px;
            padding: 3px;
            /* background-color only for content */
            background-clip: content-box;
            border: 2px solid #bbbbbb;
            background-color: #e7e6e7;
            border-radius: 50%;
            text-decoration: none;
        }
        
        /* appearance for checked radiobutton */
        input[type="radio"]:checked {
            background-color: #2d527e;
            text-decoration: none;
            border-radius: 50%;
        }
        
        /* optional styles, I'm using this for centering radiobuttons */
        .chooseOS-radio-btn {
            display: flex;
            align-items: center;
            text-decoration: none;
        }
    }
    .ram-constructor{
        width: 90%;
        .ram-slider-title{
            font-weight: bold;
            color: #2d527e;
            font-size: 11px; 
            line-height: 0.4em;
            text-transform: uppercase;
        }
    }
    .cpu-contructor{
        width: 90%;
        margin-bottom: 60px;
        .cpu-slider-title{
            font-weight: bold;
            color: #2d527e;
            font-size: 11px; 
            line-height: 0.4em;
            text-transform: uppercase;
        }
    }
    .disk-contructor{
        width: 90%;
        .disk-slider-title{
            font-weight: bold;
            color: #2d527e;
            font-size: 11px; 
            line-height: 0.4em;
            text-transform: uppercase
        }
    }
    .card-order{
        text-align: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .card-order-header{
            background-color: #3d74ab;
            padding: 20px;
            .card-order-title{
                font-size: 18px;
                font-weight: bold;
                color:  #fff;
            }   
            p{
                font-size: 13px;
                color: #ffee2d;
            }   
        }
        .card-order-main{
            background-color: #f1f8ff;
            padding: 20px;
            .text{  
                margin-top: 5px;  
                p{
                    line-height: 0.4em;
                    font-size: 12px;
                }
            }
            .card-order-btn{
                margin-top: 30px;
                text-align: center;
                margin-bottom: 30px;
                a{
                    background-color: #3d74ab;
                    color: #fff;
                    padding: 12px;
                    font-size: 12px;
                    padding-left: 17px;
                    padding-right: 17px;
                    text-decoration: none;
                }
            }
            .card-order-footer{
                p{
                    color: #616f7d;
                    font-size: 12px;
                    line-height: 1em;
                }
            }
        }
    }
}

/*
    thumbHeight = 20px,
    thumbBorderWidth = 4px,
    trackColor = #ccc,
    activeTrackColor = #777,
    trackHeight = 4px,
    thumbColor = red,
    activeThumbColor = darkOrange
  */

  input[type="range"] {
    width: 100%;
    // height: 28px; /* thumbHeight + (2 x thumbBorderWidth)*/
    -webkit-appearance: none; /*remove the line*/
    outline: none;
    /*background-color:cyan;*/
    border: 0; /*for firefox on android*/
    padding: 0 0px; /*for IE*/
    margin: 8px 0;
}

/*chrome and opera*/
input[type="range"]::-webkit-slider-runnable-track {
    background: #ccc; /*trackColor*/
    height: 4px; /*trackHeight*/
    border-radius: 4px; /*trackHeight*/
    transition: 0.3s;
}

input[type="range"]:hover::-webkit-slider-runnable-track,
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ccc; /*activeTrackColor*/
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #30527c; /*thumbColor*/
    width: 15px; /* thumbHeight + (2 x thumbBorderWidth)*/
    height: 15px; /* thumbHeight + (2 x thumbBorderWidth)*/
    border-radius: 50%;
    margin-top: -6px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
    cursor: pointer;
    //border: 4px solid #fff; /*border-width should be equal to thumbBorderWidth if you want same border width across all browsers and border-color should match the background*/
    transition: 0.3s;
}

input[type="range"]:hover::-webkit-slider-thumb,
input[type="range"]:focus::-webkit-slider-thumb {
    background: #30527c; /*activeThumbColor*/
}

/*firefox*/
input[type="range"]::-moz-focus-outer {
    border: 0;
}

input[type="range"]::-moz-range-track {
    background: #ccc; /*trackColor*/
    height: 4px; /*trackHeight*/
    border-radius: 4px; /*trackHeight*/
    /*background isn't animatable, so can't use transition*/
}

input[type="range"]:hover::-moz-range-track,
input[type="range"]:focus::-moz-range-track {
    background: #777; /*activeTrackColor*/
}

input[type="range"]::-moz-range-thumb {
    background: #30527c; /*thumbColor*/
    width: 15px; /*thumbHeight*/
    height: 15px; /*thumbHeight*/
    border-radius: 50%;
    cursor: pointer;
    //border: 4px solid #fff; /*border-width = thumbBorderWidth, border-color should match the background*/
}


input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:focus::-moz-range-thumb {
    background: #30527c; /*activeThumbColor*/
}

input[type="range"]::-moz-range-progress {
    background: #30527c; /*thumbColor*/
    border-radius: 4px; /*trackHeight*/
    height: 4px; /*trackHeight*/
}

input[type="range"]:hover::-moz-range-progress,
input[type="range"]:focus::-moz-range-progress {
    background: #30527c; /*activeThumbColor*/
}

/*edge and IE*/
input[type="range"]::-ms-track {
    background: #ccc; /*trackColor*/
    height: 4px; /*trackHeight*/
    border-radius: 4px; /*trackHeight*/
    border: none;
    color: transparent;
}

input[type="range"]:hover::-ms-track,
input[type="range"]:focus::-ms-track {
    background: #777; /*activeTrackColor*/
}

input[type="range"]::-ms-thumb {
    background: #30527c; /*thumbColor*/
    width: 15px; /*thumbHeight*/
    height: 15px; /*thumbHeight*/
    border-radius: 50%;
    border: none;
    margin: 0 4px 0; /*0 thumbBorderWidth 0*/
    box-shadow: 0 0 0 4px #fff; /*0 0 0 thumbBorderWidth #fff, box-shadow color should match the background*/
    /*cursor:pointer; doesn't work*/
}

input[type="range"]:hover::-ms-thumb,
input[type="range"]:focus::-ms-thumb {
    background: #30527c; /*activeThumbColor*/
}

input[type="range"]::-ms-tooltip {
    display: none; /*tooltip makes thumb sliding lagy*/
}

input[type="range"]::-ms-fill-lower {
    background: #30527c; /*thumbColor*/
    border-radius: 4px; /*trackHeight*/
}

input[type="range"]:hover::-ms-fill-lower,
input[type="range"]:focus::-ms-fill-lower {
    background: #30527c; /*activeThumbColor*/
}

//slider valued background color

.vertically-stacked-slider {
    padding: 8px;
}

.vertically-stacked-slider > input[type="range"] {
    padding: 0;
}

.horizontally-stacked-slider {
    display: flex;
    align-items: center;
}

.horizontally-stacked-slider > .title {
    flex: 1;
    padding: 8px;
}

.horizontally-stacked-slider > input[type="range"] {
    flex: 1;
}
@media (max-width: 1191px){

}
@media (max-width: 991px){

}
@media(max-width: 768px){

}
@media(max-width: 600px){

}
@media(max-width: 480px){

}
@media(max-width: 320px){

}
.server_value{
    .cpuvalue{
        //float:left;
    }
    .ramvalue{
        //float:left;
    }
    .hddvalue{
        //float:left;
    }
    .float-left{
        margin-right: 5px;
    }
}

.values-max-min{
    span{
        color: rgb(196, 196, 196);
        font-size: 13px;
    }
}
.margin-top-20{
    margin-top: 20px;
}

output {
  position: absolute;
  top: -15px;
  display: none;
  width: 50px;
  height: 24px;
  // border: 1px solid #e2e2e2;
  // background-color: #fff;
  border-radius: 3px;
  color: #222;
  font-size: .8em;
  line-height: 24px;
  text-align: center;
}

input[type=range]:active + output {
  display: block;
  transform: translateX(-50%);
}
input[type=range]{
    position:relative;
    -webkit-appearance: none;
    outline: none;
    transition: background 450ms ease-in;
}
input[type=range]:hover{
    cursor: pointer;
}
.cpu-contructor{
    position:relative;
}
.ram-contructor{
    position:relative;
}
.disk-contructor{
    position:relative;
}
.cost{
    color: #ca0404;
    font-size: 22px;
    font-weight: 700;
}
.sladercontainer{
    position:relative;
}