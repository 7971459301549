.card {
    border: none;
    background-color: #fff;
    width: 90%;
    margin-bottom: 40px;
    .card-text {
        margin-top: 10px;
        .card-date{
            font-size: 10px;
            color: #c5c5c5;
            margin-bottom: 1px;
        }
        .card-title{
            font-size: 20px;
            color: #4483b8;
            font-weight: bold;
        }
        .card-main-text{
            font-size: 13px;
        }
    }
}
.pagination {
    display: inline-block;
    a{
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        color: black;
        &:hover{
            background-color: #218bd5;
        }
        &:first-child{
            color: #c8c8c8;
        }
        &:last-child{
            color: #c8c8c8;
        }
        .isActive{
            background-color: #218bd5;
            color: #fff;
        }    
    }
}
@media (max-width: 1191px){

}
@media (max-width: 991px){

}
@media(max-width: 768px){

}
@media(max-width: 600px){

}
@media(max-width: 480px){

}
@media(max-width: 320px){

}