.index-relative{
    position: relative;
    top: -200px; 
}
.main{
    .card-pyrex{ 
        display: block;
        text-align: center;
        margin-bottom: 15px;
        //border:1px solid #e5e5e5;
        .card-service{
            padding: 20px;
            background-color: #fff;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3;
            .card-service-title{
                color: #0a2649;
                text-align: center;
                font-weight: bold;
                font-size: 23px;
            }
            .card-service-text{
                  /* Style the tab */
            .tab {
                text-align: center;
                background-color: #e5e8ed;
                padding-left: 20px;
                button {
                    background-color: inherit;
                    font-size: 12px;
                    border-radius: 3px;
                    padding: 8px;
                    padding-left: 8px;
                    padding-right: 8px;
                    float: left;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    padding: 10px 16px;
                    transition: 0.3s;
                    &:hover{
                        background-color: #ddd;
                    }
                    .active{
                        background-color: blue;
                        color: green;
                    }
                }
            }
            .tabcontent {
                display: none;
                padding: 6px 12px;
                border-top: none;
            }
                .card-service-btn{
                    a{
                        background-color: #b23c40;
                        color: #fff;
                        font-size: 12px;
                        border-radius: 3px;
                        text-decoration: none;
                        padding: 8px;
                        font-weight: bold;
                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }
                .cost{
                    font-weight: bold;
                    margin-top: 15px;
                    line-height: 1.3em;
                    color: #b23c40;
                }
                .card-service-text-little{
                    color: #b2b2b2;
                }
                .card-service-text-info{
                    font-size: 12px;
                    line-height: 1.8em;
                }
                .card-service-btn2{
                    a{
                        background-color: #2d527e;
                        color: #fff;
                        font-size: 15px;
                        border-radius: 3px;
                        text-decoration: none;
                        padding: 8px;
                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }
            }
            hr{
                color: #e9e9e9;
                background-color: #e9e9e9;
            }
           
            &:hover{
                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            }
        }
    }
}

.paralax{
    background-image: url("../img/banner-paralax.jpg");
    width: 100%;
    height: 550px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10px;
    .paralax-title{
        margin-bottom: 20px;
        font-weight: bold;
        color: #0a2649;
    }
    .paralax-card-coll{
        margin-top: 40px;
    }
    .paralax-card{
        margin-bottom: 20px;
        .paralax-card-title{
            h6{
                font-weight: bold;
                color: #426289;
            }
        }
        .paralax-card-text{
            p{
                font-size: 12px;
                color: #646567;
            }
            .row{
                .costs{
                    color: #4483b8;
                    font-weight: bold;
                    margin-bottom: 1px;
                }
                p{
                    color: #4483b8;
                }
                .paralax-card-btn{
                    a{
                        background-color: #b23c40;
                        color: #fff;
                        padding: 12px;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-size: 12px;
                        border-radius: 5px;
                        text-decoration: none;
                    }
                }
            }   
        }
    }
}
.second-main{
    margin-top: 20px;
    margin-bottom: 0px;
    .paralax-title{
        margin-bottom: 20px;
        font-weight: bold;
        color: #0a2649;
        span{
            a{
                font-size: 12px;
                text-decoration: underline;
                color: #5f6061;
            }
        }
    }
}
.foot{
    position: relative;
    top: -180px;
}

.contariner-service{
    position: absolute;
    list-style-type: none;
    float: left;
    display: block;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    opacity: 1; 
}
.cloud-title{
    color: #fff;
}
.card-cloud-text{
    font-size: 12px;
    color: #fff;

}

@media(max-width: 480px){
    .index-relative{
        top:0;
    }
    .paralax{
        height: auto;
    }
    .card-pyrex{
        padding:0 10px;
        padding-top: 4px; 
    }
}
@media(max-width: 768px){
    
    .paralax{
        height: auto;
    }
}

@media (min-width:1200px){
    .container{max-width:1160px}
    
}

@media (min-width:1300px){
    .container{max-width:1270px}
    .service{
        padding:0;
    }
    
}

@media (min-width:1400px){
    .container{
        max-width:1360px
    }
}


@media (min-width:1580px){
    .container{max-width:1520px}
    .row li .card-service .card-service-text .tab {
        text-align: center;
        background-color: #e5e8ed;
        padding-left: 20px;
        margin-left: 30px;
    }
}
.service > li:first-of-type a {
    padding-left:0;
}
.service > li:last-of-type a{
    padding-right:0;
}
.index-relative ul.row{
    padding:0;
}
.index-relative ul.row li{
    margin:15px;
}
.main-content{
   flex: 1;
    // padding: 40px 40px 0 40px;
}
.wrapper{
    min-height: 100%;
}

// html, body {
//     height:100%;
//     position: relative;
// }
.content_pyrex{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.cloud-title{
    color:#333;
}
.owl-pyrex{
    width: 100%;
}
.owl-carousel .owl-nav {
    color: #333;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.owl-carousel .owl-nav svg {
    color: currentColor
}

.owl-carousel .owl-nav .disabled {
    display: none
}

.owl-carousel .owl-prev,
.owl-carousel .owl-next {
    top: 40%;
    z-index: 2;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-top: -20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .3), 0 0 4px rgba(0, 0, 0, .2)
}

.owl-carousel .owl-prev {
    left: -10px
}

.owl-carousel .owl-next {
    right: -10px
}
